// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")

require("./Cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// NOTE: this is here because otherwise it gets loaded separately and the jQuery used by this
// and the one used by Cocoon are different and then the events are not proper fired.
$(() => {
  $("#add_ireland_selections .add_selection_link").on(
    "cocoon:before-insert",
    function (event, insertedItem) {
        const currentCount = $("#add_ireland_selections .form-group").length;
        
        if (currentCount >= 5) {
            event.preventDefault();
        }
    }
  );
});